




























































































import { defineComponent, PropType } from '@vue/composition-api';
import { IProductItem } from '@/models/product';
import isUrl from '@/utils/isUrl';
import router from '@/router';

// @ is an alias to /src
export default defineComponent({
  props: {
    config: {
      type: Object as PropType<IProductItem>,
      required: true,
    },
    route: String,
    isCompact: Boolean,
    showExhibitor: Boolean,
    showAllFields: Boolean,
    categoriesHidden: Boolean,
  },
  setup() {
    const event = parseInt(router.currentRoute.params.event, 10);
    const visitor = parseInt(router.currentRoute.params.visitor, 10);
    return {
      isUrl,
      event,
      visitor,
    };
  },
  computed: {
    keys(): string[] {
      return Object.keys(this.config || {}).filter(
        (key) => !['image', 'title'].includes(key) && this.config[key as keyof IProductItem],
      );
    },
  },
});
