
































































































































































































import { MutationPayload } from 'vuex';
import { getProductItem, getProductList } from '@/api/products';
import Modal from '@/components/modal.vue';
import ProductCard from '@/partials/ProductCard.vue';
import { IProductItem } from '@/models/product';
import store from '@/store';
import Textarea from '@/components/form/textarea.vue';
import { IFavorite, IFavoriteParams } from '@/models/visitor';
import { IProductParams } from '@/store/modules/products';
import { productDetailModal } from './Actions/index';
import { addFavorite, deleteFavorite, getFavorites } from '@/api/visitors';
import router from '@/router';
import { defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';

// @ is an alias to /src

export default defineComponent({
  components: {
    Modal,
    Textarea,
    ProductCard,
  },
  data() {
    return {
      modal: productDetailModal,
      loading: false,
      productId: 0,
      favorite: {} as IFavorite,
      product: {} as IProductItem,
      event: 0,
      productsRelated: [] as IProductItem[],
      activeImage: '',
      favoritesCount: 0,
    };
  },

  setup() {
    const { contextExhibitor, contextEvent, contextVisitor, translations } = useContext();

    return {
      contextExhibitor,
      contextEvent,
      contextVisitor,
      translations,
    };
  },

  created() {
    this.initProduct();
  },

  methods: {
    initProduct() {
      this.productId = parseInt(this.$route.params.product, 10);

      this.event = parseInt(this.$route.params.event, 10);

      getProductItem(this.productId).then((response) => {
        this.product = response.data;
        this.activeImage = this.product.image_1 ? this.product.image_1.value : '';
        const productsParams: IProductParams = {
          event: this.event,
          page: 1,
          page_size: 12,
          exhibitor: this.product.exhibitor,
          submitted: true,
        };

        getProductList(productsParams).then((response) => {
          this.productsRelated = response.data.results.filter(
            (product) => product.id !== this.productId,
          );
        });
        this.$gtag.event(`product_viewed_${this.product.id}`, {
          event_category: `event_${this.event}`,
          event_label: `visitor_${this.contextVisitor.id}`,
        });
      });
      const favoritesParams: IFavoriteParams = {
        source_type: 'pr',
        source_id: this.productId,
      };
      getFavorites(favoritesParams).then((response) => {
        this.favoritesCount = response.data.item_count;
      });

      this.getFavorites();

      store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setContextVisitor' && mutation.payload.id) {
          this.getFavorites();
        }
        if (mutation.type === 'setLanguage') {
          getProductItem(this.productId).then((response) => {
            this.product = response.data;
            this.$gtag.event(`product_viewed_${this.product.id}`, {
              event_category: `event_${this.event}`,
              event_label: `visitor_${this.contextVisitor.id}`,
            });
          });
        }
      });
    },

    async addFavorite() {
      if (this.contextVisitor.id) {
        const response = await addFavorite({
          source_type: 'pr',
          source_id: this.productId,
          visitor: this.contextVisitor.id,
        });
        this.favorite = response.data;
      } else {
        store.commit('openLoginPrompt');
      }
    },

    async deleteFavorite() {
      await deleteFavorite(this.favorite.id);
      this.favorite = {} as IFavorite;
    },

    async getFavorites() {
      if (this.contextVisitor.id) {
        const response = await getFavorites({
          source_type: 'pr',
          visitor: this.contextVisitor.id,
          source_id: this.productId,
        });
        const favorites = response.data.results;
        if (favorites[0]) {
          [this.favorite] = favorites;
        }
      }
    },

    productInteraction() {
      if (this.contextVisitor.id) {
        router.push(`/event/${this.event}/product/${this.product.id}/detail`);
      } else {
        store.commit('openLoginPrompt');
      }
    },
  },
  watch: {
    $route() {
      this.initProduct();
    },
  },
});
