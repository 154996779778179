import { IModal } from '@/components';
import router from '@/router';
import store from '@/store';
import { ITextarea } from '@/components/form/index';
import { ITranslations } from '@/translations/index';

export const productDetailModal: IModal = {
  isActive: true,
  isCard: true,
  close: () => {
    const event = parseInt(router.currentRoute.params.event, 10);
    const product = parseInt(router.currentRoute.params.product, 10);
    router.push(
      `/event/${event}/product/${product}`,
    );
  },
};

export const coverLetterConfig = () => {
  const translations = store.getters.getTranslations as ITranslations;
  const textarea: ITextarea = {
    id: 'cover-letter',
    name: 'cover_letter',
    label: translations.visitors.exhibitors.cover_letter,
    placeholder: translations.visitors.exhibitors.cover_letter_text,
    value: '',
  };

  return textarea;
};

export const modal: IModal = {
  isLarge: false,
  isActive: true,
  isCard: true,
  close: () => {
    const product = parseInt(router.currentRoute.params.product, 10);
    const event = parseInt(router.currentRoute.params.event, 10);
    router.push(
      `/event/${event}/product/${product}`,
    );
  },
};
