import {
  computed, ComputedRef,
} from '@vue/composition-api';
import { Route } from 'vue-router';
import { IExhibitor } from '@/models/exhibitor';
import { IVisitor } from '@/models/visitor';
import { ITranslations } from '@/translations/index';
import { IEventV2 } from '@/models/event';
import store from '@/store';
import router from '@/router';
import { ISession } from '../models/session';

export interface IUseContext {
  userExhibits: ComputedRef<number[]>,
  relatedVisitors: ComputedRef<IVisitor>,
  relatedExhibitors: ComputedRef<IExhibitor>,
  userManages: ComputedRef<number[]>,
  translations: ComputedRef<ITranslations>,
  language: ComputedRef<IVisitor>,
  userRolesSet: ComputedRef<boolean>,
  userIsExhibitor: ComputedRef<boolean>,
  userIsVisitor: ComputedRef<boolean>,
  userExhibitor: ComputedRef<IExhibitor>,
  userVisitor: ComputedRef<IVisitor>,
  userIsManager: ComputedRef<boolean>,
  contextEvent: ComputedRef<IEventV2>,
  contextVisitor: ComputedRef<IVisitor>,
  contextExhibitor: ComputedRef<IExhibitor>,
  userAdmin: ComputedRef<boolean>,
  relatedEvents: ComputedRef<IEventV2[]>,
  userVisits: ComputedRef<number[]>,
  contextSession: ComputedRef<ISession>,
  contextEmbedUrl: ComputedRef<IVisitor>,
  currentRoute: ComputedRef<Route>,
}
// GETTERS

const userRolesSet = computed(() => <boolean>store.getters.getUserRolesSet);
const userAdmin = computed(() => <boolean>store.getters.userAdmin);
const userExhibits = computed(() => <boolean>store.getters.userExhibits);
const eventLanguages = computed(() => store.getters.getEventLanguages as string[]);
const relatedVisitors = computed(() => store.getters.getRelatedVisitors as IVisitor[]);
const relatedExhibitors = computed(() => store.getters.getRelatedExhibitors as IExhibitor[]);
const userManages = computed(() => store.getters.userManages as number[]);
const translations = computed(() => store.getters.getTranslations as ITranslations);
const language = computed(() => store.getters.getLanguage as 'en' | 'pt' | 'es');
const contextEvent = computed(() => store.getters.getContextEvent as IEventV2);
const contextVisitor = computed(() => store.getters.getContextVisitor as IVisitor);
const contextExhibitor = computed(() => store.getters.getContextExhibitor as IExhibitor);
const relatedEvents = computed(() => store.getters.getRelatedEvents as IEventV2[]);
const userVisits = computed(() => store.getters.userVisits as number[]);
const contextSession = computed(() => store.getters.getContextSession as ISession);
const routeIsExhibitor = computed(() => router.currentRoute.path.includes('/manage-exhibitor'));
const currentRoute = computed(() => router.currentRoute as Route);

// COMPUTED

const userIsExhibitor = computed(() => relatedExhibitors.value && relatedExhibitors.value.some((exhibitor) => exhibitor.event === contextEvent.value.id));
const userIsVisitor = computed(() => relatedVisitors.value && relatedVisitors.value.some((visitor) => visitor.event === contextEvent.value.id));
const userExhibitor = computed(() => relatedExhibitors.value && relatedExhibitors.value.find((exhibitor) => exhibitor.event === contextEvent.value.id));
const userVisitor = computed(() => relatedVisitors.value && relatedVisitors.value.find((visitor) => visitor.event === contextEvent.value.id));
const userIsManager = computed(() => userManages.value && userManages.value.some((event) => event === contextEvent.value.id));
const contextEmbedUrl = computed(() => {
  let sessionUrl = contextSession.value.external_link;

  if ((!sessionUrl || sessionUrl === '') && contextSession.value.id) {
    sessionUrl = `https://meet.beamian.com/${contextSession.value.id}`;
  }
  return sessionUrl;
});
export default function useContext() {

  return {
    userExhibits,
    relatedVisitors,
    relatedExhibitors,
    userManages,
    translations,
    language,
    userIsExhibitor,
    userIsVisitor,
    userExhibitor,
    userVisitor,
    userIsManager,
    contextEvent,
    contextVisitor,
    contextExhibitor,
    userAdmin,
    relatedEvents,
    userVisits,
    contextSession,
    contextEmbedUrl,
    currentRoute,
    userRolesSet,
    routeIsExhibitor,
    eventLanguages,
  };
}
